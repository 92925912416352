import { defineStore } from 'pinia';

const useAuthStore = defineStore('auth', {
  state: () => ({
    _auth: null,
    _connected: false,
    _connectionId: '',
    _signUpInformation: null,
    _errorConnectionPeer: false,
    _peerConnected: false
  }),
  getters: {
    authInfor: (state) => state._auth,
    connected: (state) => state._connected,
    peerConnected: (state) => state._peerConnected,
    connectionId: (state) => state._connectionId,
    signUpInformation: (state) => state._signUpInformation
  },
  actions: {
    setAuthInfor(auth) {
      this._auth = auth
    },
    setAuthConnected() {
      this._connected = true
    },
    setPeerConnected(value) {
      this._peerConnected = value
    },
    setErrorConnectionPeer(value) { 
      this._errorConnectionPeer = value
    },
    logout() {
      localStorage.clear()
      // //remove state local with old connectionId 
      // //if still existed old connectionId it will be can not connect peer
      // localStorage.removeItem('auth')
    },
    setConnectionId(connectionId) {
      this._connectionId = connectionId
    },
    setSignupInformation(signupInformation) {
      this._signUpInformation = signupInformation
    },
  },
  persist: true
})

export default useAuthStore