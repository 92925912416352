import { default as forgot_45passwordcBWiw0xyOQMeta } from "/opt/buildhome/repo/pages/forgot-password.vue?macro=true";
import { default as Index60I45tvXLKMeta } from "/opt/buildhome/repo/pages/ForgotPassword/Index.vue?macro=true";
import { default as Index7PmIcEuMzvMeta } from "/opt/buildhome/repo/pages/Group/Index.vue?macro=true";
import { default as ErrorPeersXARDCe0NmMeta } from "/opt/buildhome/repo/pages/Home/components/ErrorPeer.vue?macro=true";
import { default as LeftContent1f3GenkSzgMeta } from "/opt/buildhome/repo/pages/Home/components/LeftContent.vue?macro=true";
import { default as TabChatV7JsJqR9DTMeta } from "/opt/buildhome/repo/pages/Home/components/TabChat.vue?macro=true";
import { default as TabContactvMeQ5rZBVgMeta } from "/opt/buildhome/repo/pages/Home/components/TabContact.vue?macro=true";
import { default as TabMakeFriendFc8nDWYyKlMeta } from "/opt/buildhome/repo/pages/Home/components/TabMakeFriend.vue?macro=true";
import { default as TabProfileFCxwtH7uahMeta } from "/opt/buildhome/repo/pages/Home/components/TabProfile.vue?macro=true";
import { default as ToolbarIScZRrCaucMeta } from "/opt/buildhome/repo/pages/Home/components/Toolbar.vue?macro=true";
import { default as VersionA6AKW2yUIeMeta } from "/opt/buildhome/repo/pages/Home/components/Version.vue?macro=true";
import { default as ViewMobileKEojOMGz08Meta } from "/opt/buildhome/repo/pages/Home/components/ViewMobile.vue?macro=true";
import { default as ViewPCwM1GDPaB6DMeta } from "/opt/buildhome/repo/pages/Home/components/ViewPC.vue?macro=true";
import { default as ViewTabletVfyiZLoPRDMeta } from "/opt/buildhome/repo/pages/Home/components/ViewTablet.vue?macro=true";
import { default as IndexQXxuVqPNlvMeta } from "/opt/buildhome/repo/pages/Home/Index.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as login4dfr6kRqBoMeta } from "/opt/buildhome/repo/pages/login.vue?macro=true";
import { default as Index9k0qcdtmsJMeta } from "/opt/buildhome/repo/pages/Login/Index.vue?macro=true";
import { default as IndexspJDmkv528Meta } from "/opt/buildhome/repo/pages/Peer/Index.vue?macro=true";
import { default as registerynsVf0yvMzMeta } from "/opt/buildhome/repo/pages/register.vue?macro=true";
import { default as Index9oFhCzEhwLMeta } from "/opt/buildhome/repo/pages/Register/Index.vue?macro=true";
import { default as verifywjVOsKwiwqMeta } from "/opt/buildhome/repo/pages/verify.vue?macro=true";
import { default as ConfirmEmailOHjxxQgffeMeta } from "/opt/buildhome/repo/pages/Verify/components/ConfirmEmail.vue?macro=true";
import { default as ResetPassword7IZ4yXu82CMeta } from "/opt/buildhome/repo/pages/Verify/components/ResetPassword.vue?macro=true";
import { default as IndexCr14bORNkMMeta } from "/opt/buildhome/repo/pages/Verify/Index.vue?macro=true";
export default [
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/opt/buildhome/repo/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "ForgotPassword-Index",
    path: "/ForgotPassword/Index",
    component: () => import("/opt/buildhome/repo/pages/ForgotPassword/Index.vue").then(m => m.default || m)
  },
  {
    name: "Group-Index",
    path: "/Group/Index",
    component: () => import("/opt/buildhome/repo/pages/Group/Index.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-ErrorPeer",
    path: "/Home/components/ErrorPeer",
    component: () => import("/opt/buildhome/repo/pages/Home/components/ErrorPeer.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-LeftContent",
    path: "/Home/components/LeftContent",
    component: () => import("/opt/buildhome/repo/pages/Home/components/LeftContent.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-TabChat",
    path: "/Home/components/TabChat",
    component: () => import("/opt/buildhome/repo/pages/Home/components/TabChat.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-TabContact",
    path: "/Home/components/TabContact",
    component: () => import("/opt/buildhome/repo/pages/Home/components/TabContact.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-TabMakeFriend",
    path: "/Home/components/TabMakeFriend",
    component: () => import("/opt/buildhome/repo/pages/Home/components/TabMakeFriend.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-TabProfile",
    path: "/Home/components/TabProfile",
    component: () => import("/opt/buildhome/repo/pages/Home/components/TabProfile.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-Toolbar",
    path: "/Home/components/Toolbar",
    component: () => import("/opt/buildhome/repo/pages/Home/components/Toolbar.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-Version",
    path: "/Home/components/Version",
    component: () => import("/opt/buildhome/repo/pages/Home/components/Version.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-ViewMobile",
    path: "/Home/components/ViewMobile",
    component: () => import("/opt/buildhome/repo/pages/Home/components/ViewMobile.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-ViewPC",
    path: "/Home/components/ViewPC",
    component: () => import("/opt/buildhome/repo/pages/Home/components/ViewPC.vue").then(m => m.default || m)
  },
  {
    name: "Home-components-ViewTablet",
    path: "/Home/components/ViewTablet",
    component: () => import("/opt/buildhome/repo/pages/Home/components/ViewTablet.vue").then(m => m.default || m)
  },
  {
    name: "Home-Index",
    path: "/Home/Index",
    component: () => import("/opt/buildhome/repo/pages/Home/Index.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/",
    meta: indexniDiYCWjuTMeta || {},
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/opt/buildhome/repo/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "Login-Index",
    path: "/Login/Index",
    component: () => import("/opt/buildhome/repo/pages/Login/Index.vue").then(m => m.default || m)
  },
  {
    name: "Peer-Index",
    path: "/Peer/Index",
    component: () => import("/opt/buildhome/repo/pages/Peer/Index.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/opt/buildhome/repo/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "Register-Index",
    path: "/Register/Index",
    component: () => import("/opt/buildhome/repo/pages/Register/Index.vue").then(m => m.default || m)
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/opt/buildhome/repo/pages/verify.vue").then(m => m.default || m)
  },
  {
    name: "Verify-components-ConfirmEmail",
    path: "/Verify/components/ConfirmEmail",
    component: () => import("/opt/buildhome/repo/pages/Verify/components/ConfirmEmail.vue").then(m => m.default || m)
  },
  {
    name: "Verify-components-ResetPassword",
    path: "/Verify/components/ResetPassword",
    component: () => import("/opt/buildhome/repo/pages/Verify/components/ResetPassword.vue").then(m => m.default || m)
  },
  {
    name: "Verify-Index",
    path: "/Verify/Index",
    component: () => import("/opt/buildhome/repo/pages/Verify/Index.vue").then(m => m.default || m)
  }
]