import payload_plugin_e0k4tW9bdI from "/opt/buildhome/repo/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.mjs";
import revive_payload_client_4sVQNw7RlN from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/opt/buildhome/repo/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/buildhome/repo/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/opt/buildhome/repo/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/opt/buildhome/repo/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_mik7N71GmK from "/opt/buildhome/repo/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/opt/buildhome/repo/.nuxt/vuefire-plugin.mjs";
import plugin_sb2LSEEy8f from "/opt/buildhome/repo/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import plugin_AUP22rrBAc from "/opt/buildhome/repo/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import sql_yZXdFWm02Y from "/opt/buildhome/repo/plugins/sql.js";
import firebaseui_client_NkROhApHuJ from "/opt/buildhome/repo/plugins/firebaseui.client.js";
import sentry_clients_zNy3RJOUZp from "/opt/buildhome/repo/plugins/sentry.clients.js";
export default [
  payload_plugin_e0k4tW9bdI,
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_mik7N71GmK,
  vuefire_plugin_8cq4FnzXKb,
  plugin_sb2LSEEy8f,
  plugin_AUP22rrBAc,
  sql_yZXdFWm02Y,
  firebaseui_client_NkROhApHuJ,
  sentry_clients_zNy3RJOUZp
]