export class ChatHistory1716431048154 {
    name = 'ChatHistory1716431048154'

    async up(queryRunner) {
        await queryRunner.query(`CREATE TABLE "message_history" ("id" varchar PRIMARY KEY NOT NULL, "from" varchar, "to" varchar, "message" varchar, "type" varchar, "files" text, "createdTime" varchar, "updatedTime" varchar)`);
    }

    async down(queryRunner) {
        await queryRunner.query(`DROP TABLE "message_history"`);
    }
}
