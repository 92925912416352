import chatAppDataSource from '~/databases/datasource/ChatAppDataSource';
import { DB_NAME } from '@/utils/constants';

export const initializeDataSources = async () => {
  const awaitDataSourceInitialization = async () => {
    return new Promise((resolve, reject) => {
      chatAppDataSource().then(res => {
        if (res.isInitialized) {
          res.runMigrations().then(async () => {
            await commit(DB_NAME)
            resolve(res);
          }).catch((e) => {
            console.error('Error running migrations:', e);
            reject(null);
          })
        } else {
          reject(null);
        }
      }).catch((e) => {
        reject(e);
      })
    })
}

try {
  const dataSource = await awaitDataSourceInitialization();
  return dataSource
} catch (error) {
  console.error(`Error initializing DataSource ${DB_NAME}:`, error);
}  
};

export const getCountOfElements = (async (connection, entity) => {
  // Get the repository for your entity
  const repository = connection?.getRepository(entity);
  // Use the count() method to query the count of elements in the table
  const count = await repository.count();

  return count;
});


//synch database into store IndexedDB
export const commit = async (dbName = DB_NAME) => {
  // if (sqliteParams.platform === 'web' && typeof dbName === 'string') {
  //   await sqliteParams.connection.saveToStore(dbName);
  // }
}

