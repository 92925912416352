import { useAuthStore } from '~/store'

export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.server) return
  if (import.meta.client) {
    const authStore = useAuthStore()
    const loggedIn = authStore.authInfor
  
    if (!loggedIn && ['/login', '/register', '/verify', '/forgot-password'].includes(to.path)) {
      return 
    }
    if (!loggedIn) {
      return navigateTo('/login')
    }
  }
})
