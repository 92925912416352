import { EntitySchema } from 'typeorm';
import { MessageHistory } from '~/databases/models/MessageHistory'

export const ChatHistorySchema = new EntitySchema({
  name: "ChatHistorySchema",
  target: MessageHistory,
  columns: {
    id: {
      primary: true,
      type: "varchar"
    },
    from: {
      type: "varchar",
      nullable: true
    },
    to: {
      type: "varchar",
      nullable: true
    },
    message: {
      type: "varchar",
      nullable: true
    },
    type: {
      type: "varchar",
      nullable: true
    },
    files: {
      type: "simple-array",
      nullable: true
    },
    createdTime: {
      type: "varchar",
      nullable: true
    },
    updatedTime: {
      type: "varchar",
      nullable: true
    },
  }
});

