<template>
  <div class="error-wrapper">
    <div class="error">
      <img src="/assets/images/error.png" alt="Error Image" class="q-mb-md" />
      <h4 class="q-mb-md">{{ errorMessage }}</h4>
      <p class="q-mb-md">{{ errorDescription }}</p>
      <q-btn rounded color="blue-13" @click="goHome">Back to home</q-btn>
    </div>
  </div>
</template>

<script setup>
import { toRef, computed } from 'vue';
import { useAuthStore } from '@/store';


const props = defineProps({
  error: {
    type: Object,
    default: () => ({
      statusCode: 500,
      message: 'An error occurred'
    }),
  },
})

const authStore = useAuthStore();
const error = toRef(props, 'error');

const errorMessage = computed(() => {
  switch (error.value?.statusCode) {
    case 400:
      return 'Bad Request';
    case 401:
      return 'Unauthorized';
    case 404:
      return 'Page Not Found';
    case 500:
      return 'Oops! Something went wrong.';
    default:
      return 'Oops! Something went wrong.';
  }
});

const errorDescription = computed(() => {
  switch (error.value?.statusCode) {
    case 400:
      return 'The request could not be understood or was missing required parameters.';
    case 401:
      return 'You are not authorized to view this page.';
    case 404:
      return 'The page you are trying to access does not exist.';
    case 500:
      return 'An unexpected error occurred on the server.';
    default:
      return 'An unexpected error occurred. Please try again later.';
  }
});

const goHome = () => {
  location.reload();
  authStore.setErrorConnectionPeer(false);
};
</script>

<style lang="scss" scoped>
.error-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  height: 100vh;
  min-width: 500px;
}

.error {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  img {
    max-width: 300px;
  }

  button {
    text-transform: capitalize;
  }
}
</style>
