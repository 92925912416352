export class User1715929208033{
    name = 'User1715929208033'

    async up(queryRunner) {
        await queryRunner.query(`CREATE TABLE "participant" ("id" varchar PRIMARY KEY NOT NULL, "name" varchar, "phoneNumber" varchar, "status" varchar, "email" varchar, "connectionId" varchar, "avatar" varchar)`);
    }

    async down(queryRunner) {
        await queryRunner.query(`DROP TABLE "participant"`);
    }

}
