
export const WRONG_USERNAME_PASSWORD = "Wrong email or password!";
export const SOMETHING_WENT_WRONG =
  "Something went wrong, please contact us for further support!";
export const MISSING_EMAIL =
  "Missing email, please enter your email!";

export const WRONG_LINK_OR_EXPIRED = "This link is invalid or expired.";

export const WRONG_EMAIL = "Wrong email, please enter your email correctly!";

export const ACTION = {
  VIDEO: 'video',
  AUDIO: 'audio',
  SHARE_SCREEN: 'share_screen',
  CHAT: 'chat',
}

export const DEVICE = {
  CAMERA: 'camera',
  MICROPHONE: 'microphone',
  SCREEN: 'screen',
}

export const PERMISSION = {
  GRANTED: 'granted',
  DENIED: 'denied',
  PROMPT: 'prompt',
  PERMISSION_DENIED: 'Permission denied',
}

export const STATUS = {
  ONLINE: 'online',
  OFFLINE: 'offline',
  BUSY: 'busy',
}

export const CONSTRAINT = {
  AUDIO: {
    audio: true,
  },
  VIDEO: {
    audio: true,
    video: true,
  }
}

export const VIEW_PORT_MOBILE = 480 //max width mobile
export const SIZE_VIDEO_IN_MOBILE = 430

//SCREEN
export const SCREEN = {
  MENU_CHAT: 'MENU_CHAT',
  MENU_CONTACT: 'MENU_CONTACT',
  MENU_GLOBAL: 'MENU_GLOBAL',

  CHAT: 'CHAT',
  CONTACT: 'CONTACT',
  GLOBAL: 'GLOBAL',
}

//TYPE MESSAGE
export const MESSAGE_TYPE = {
  PAIR_CLOSED: "PAIR_CLOSED",
  IN_OTHER_CALL: "IN_OTHER_CALL",
  SHARE_SCREEN_CLOSED: "SHARE_SCREEN_CLOSED",
  CHAT: "CHAT",
}


export const VERIFY_UPLOAD_IMAGE_URL="https://upload-image.cdhome.cc"
export const DISPLAY_IMAGE_URL = "https://image.cdhome.cc"

export const VOLUME_THRESHOLD = 130

export const AUTH_TYPE = {
  LOGIN: 'LOGIN',
  SIGN_UP: 'SIGN_UP',
}

export const DB_NAME = 'ChatAppDB'

export const USER_OFFLINE = 'User is currently unavailable. You can try again later!'