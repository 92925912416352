import { defineStore } from 'pinia'

const useLoadingStore = defineStore('loading', {
  state: () => ({
    isLoading: true  // Start with loading as true
  }),
  actions: {
    setLoading(value) {
      this.isLoading = value
    }
  }
})


export default useLoadingStore
