import * as Sentry from '@sentry/vue'

const lazyLoadSentryIntegrations = async ()  => {
  // don't load on server
  if (!process.client) return;

  Sentry.replayIntegration({
    maskAllText: false,
    blockAllMedia: false,
  })
}

const getSentryIntegrations = () => {
  // don't load on server
  if (!process.client) return [];

  const router = useRouter();
  const browserTracing = Sentry.browserTracingIntegration();

  return [browserTracing];
}


export default defineNuxtPlugin((nuxtApp) => {
  const { public: { sentry, consoleLog } } = useRuntimeConfig()

  if (!sentry.dsn) {
    return
  }
  if (consoleLog) console.log(sentry)
  // TODO: filter known error like permission
  var MessagesInWhiteList =  [
    "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",
    "AbortError: The operation was aborted."
  ]
  Sentry.init({
    app: nuxtApp.vueApp,
    dsn: sentry.dsn,
    release: sentry.release || 'FE-1.0.1',
    environment: sentry.environment,
    integrations: getSentryIntegrations(),

    // integrations: [
    //   Sentry.browserTracingIntegration(),  
    //   Sentry.replayIntegration({
    //     maskAllText: false,
    //     blockAllMedia: false,
    //   }),
    // ],

    // tracesSampleRate: sentry.tracesSampleRate || 0.1,
    tracesSampler: samplingContext => {
      // Examine provided context data (including parent decision, if any) along
      // with anything in the global namespace to compute the sample rate or
      // sampling decision for this transaction
  
      if (1) {
        // These are important - take a big sample
        return 1;
      } else if (0) {
        // These are less important or happen much more frequently - only take 1%
        return 0.01;
      } else if (0) {
        // These aren't something worth tracking - drop all transactions like this
        return 0;
      } else {
        // Default sample rate
        return sentry.tracesSampleRate || 0.1
      }
    },
    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['https://jura.cdhome.cc', 'http://localhost:4200', 'http://localhost:3000'],

    replaysSessionSampleRate: sentry.replaysSessionSampleRate || 0.1,
    replaysOnErrorSampleRate: sentry.replaysOnErrorSampleRate || 1.0,

    ignoreErrors: MessagesInWhiteList,
		beforeSend(event, hint) {
			if (consoleLog) console.log(event, hint)
			if (MessagesInWhiteList.includes(hint?.originalException?.message)) {
				if (consoleLog) console.log('catch')
				return null
			  }
			if (event !== undefined && event.exception !== undefined && event.exception.values !== undefined
                    && event.exception.values.length > 0) {
                var e = event.exception.values[0];
                if (e.type === 'UnhandledRejection' && e.value && e.value.includes('promise rejection')) {
                    return null;
                }
            }
			return event
		},
  })
   // Lazy-load the replay integration to reduce bundle size
  lazyLoadSentryIntegrations()
})
