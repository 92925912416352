import { EntitySchema } from 'typeorm'
import {Participant} from '@/databases/models/Participant'

export const UserSchema = new EntitySchema({
  name: "UserSchema",
  target: Participant,
  columns: {
      id: {
          primary: true,
          type: "varchar",
      },
      name: {
          type: "varchar",
          nullable: true
      },
      phoneNumber: {
          type: "varchar",
          nullable: true
      },
      status: {
          type: "varchar",
          nullable: true
      },
      email: {
          type: "varchar",
          nullable: true
      },
      connectionId: {
          type: "varchar",
          nullable: true
      },
      avatar: {
          type: "varchar",
          nullable: true
      }
  }
});