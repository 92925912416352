export class Participant {
  constructor(id, name, phoneNumber, status, email, connectionId, avatar) {
    this.id = id;
    this.name = name;
    this.phoneNumber = phoneNumber;
    this.status = status;
    this.email = email;
    this.connectionId = connectionId;
    this.avatar = avatar;
  }
}
