import "reflect-metadata";
import { DataSource } from 'typeorm';
import initSqlJs from "sql.js";
import { UserSchema } from "@/databases/entities/UserSchema";
import { ChatHistorySchema } from '@/databases/entities/ChatHistorySchema';
import { User1715929208033 } from '@/databases/migrations/user/1715929208033-User';
import { ChatHistory1716431048154 } from '@/databases/migrations/chat-history/1716431048154-ChatHistory';
import localforage from "localforage";
import {DB_NAME} from '@/utils/constants'

window.localforage = localforage;

let sqlPromise = null;

const initializeSQL = async () => {
  if (!sqlPromise) {
    sqlPromise = initSqlJs({
      locateFile: () => '/assets/sql-wasm.wasm',
    });
  }
  return sqlPromise;
};

const chatAppDataSource = async () => {
  const SQL = await initializeSQL()
  const dataSourceConfig = {
    type: 'sqljs',
    driver: SQL,
    location: DB_NAME,
    autoSave: true,

    entities: [UserSchema, ChatHistorySchema],

    //Do vite and webpack support dynamic import?
    // migrations: ["../migrations/user/*{.ts,.js}", "../migrations/chat-history/*{.ts,.js}"],
    migrations: [User1715929208033, ChatHistory1716431048154],
    subscribers: [],
    logging: ['query', 'error', 'schema'],
    synchronize: false,     // !!!You will lose all data in database if set to `true`
    migrationsRun: false,
    useLocalForage: true,
  };

  // DataSource instance used to connect to the database
  // DataSource trong TypeORM là trung tâm của mọi tương tác với cơ sở dữ liệu. Nó chứa cấu hình kết nối, entities, migrations, và các thành phần khác. Bạn cần khởi tạo và kết nối DataSource trước khi có thể sử dụng nó để thực hiện các thao tác trên cơ sở dữ liệu.
  const dataSource = new DataSource(dataSourceConfig);

  await dataSource.initialize();

  return dataSource;
}

export default chatAppDataSource
