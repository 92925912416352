import { cloneDeep } from 'lodash';
import { defineStore } from 'pinia';
import {toRaw} from 'vue'

const useChatStore = defineStore('chat', {
  state: () => ({
    _messages: [],
    _friends: [],
    _friend: null,
    _action: 'CHAT',
    _countInvites: 0,
  }),
  getters: {
    messages: (state) => state._messages,
    friends: (state) => state._friends,
    friend: (state) => state._friend,
  },
  actions: {
    setAction(action){
      this._action = action
    },
    pushMessage({from, message}) {
      const msg = {from, message}
      this._messages = [
        ...this._messages,
        msg
      ]
    },
    setListFriends(frs) {
      this._friends = frs
    },
    setSelectFriend(f) {
      this._friend = f
    },
    getFriends(connId) {
      return this._friends.filter((el) => {
        const friendId = el.split(':')[1]
        return friendId !== connId
      }).map((el, idx) => {
        const friendId = el.split(':')[1]
        const friend = {
          id: friendId,
          avatar: `https://cdn.quasar.dev/img/avatar${idx + 1}.jpg`,
          name: el,
        }

        console.log('friend', friend);
        return friend
      })
    },
    getMessagesByFriend() {
      const msgs = cloneDeep(this.messages)
      //get name friend
      const friend = toRaw(this._friend)
      const name = friend?.name.split(":")[0]
      return msgs.filter(m => m.from === 'me' && m.from === name) || []
    },
    setCountInvites(val) {
      this._countInvites = val
    }
  },
  persist: true
})

export default useChatStore
