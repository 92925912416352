export class MessageHistory {
  constructor(id, from, to, message, type, files, createdTime, updatedTime) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.message = message;
    this.type = type;
    this.files = files;
    this.createdTime = createdTime;
    this.updatedTime = updatedTime
  }
}
