import { defineStore } from 'pinia';

const useSocketStore = defineStore('socket', {
  state: () => ({
    _socket: null
  }),
  getters: {
    socket: (state) => state._socket,
  },
  actions: {
    setSocket(f) {
      this._socket = f
    },
  },
  persist: true
})

export default useSocketStore
